import React, { useContext } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { AuthContext } from '../context/Auth'
import fire from '../../firebase'
import Logo from '../logo/Logo'
import './LogIn.css'

const LogIn = () => {
    const { currentUser } = useContext(AuthContext)

    const onFinish = (values) => {
        try {
            fire.firebase
                .auth()
                .signInWithEmailAndPassword(values.email, values.password)
        } catch (error) {
            console.log(`Error authenticating user - ${error}`)
        }
    }

    if (currentUser) return <Redirect to="/dashboard" />

    return (
        <div className="login-container">
            <Link to="/">
                <Logo size={50} color="#1890ff" strokeWidth={2.0} fill="none" />
            </Link>
            <Form
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email Address!'
                        }
                    ]}
                >
                    <Input
                        prefix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!'
                        }
                    ]}
                >
                    <Input
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Log in
                    </Button>
                    do not have an account? <a href="/signup">sign up.</a>
                </Form.Item>
            </Form>
        </div>
    )
}

export default LogIn
