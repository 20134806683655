import React from 'react'
import { FrownOutlined } from '@ant-design/icons'

function NotFound() {
    return (
        <div className="flex-center">
            <FrownOutlined style={{ fontSize: '50px', padding: '20px' }} />
            <p>
                404 - Not sure where your page went, but we‘re looking for it!
            </p>
        </div>
    )
}

export default NotFound
