import React from 'react'
import PropTypes from 'prop-types'
import './Logo.css'

function Logo(props) {
    return (
        <div className="logo">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon-logo"
                width={props.size}
                height={props.size}
                strokeWidth={props.strokeWidth}
                stroke={props.color}
                viewBox="0 0 24 24"
                fill={props.fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6.8 11a6 6 0 1 0 10.396 0l-5.197 -8l-5.2 8z" />
            </svg>
        </div>
    )
}

Logo.propTypes = {
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
    fill: PropTypes.string
}

export default Logo
