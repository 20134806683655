import React, { useContext } from 'react'
import { Layout, Row, Col, Button, Typography } from 'antd'
import { LoginOutlined, UserAddOutlined } from '@ant-design/icons'
import { AuthContext } from '../context/Auth'
import Logo from '../logo/Logo'
import './Home.css'

const { Content, Footer } = Layout
const { Title } = Typography

const Home = () => {
    const { currentUser } = useContext(AuthContext)
    return (
        <Layout className="layout-home">
            <Content className="site-layout-content">
                <Row className="contents">
                    <Col xs={0} xl={14} className="welcome-container">
                        <Title level={2} className="welcome-text">
                            Savor each moment.
                        </Title>
                    </Col>
                    <Col xs={24} xl={10} className="actions-container">
                        <div className="actions-box">
                            <Row justify="center">
                                <Logo
                                    size={115}
                                    color="#1890ff"
                                    strokeWidth={1.5}
                                    fill="none"
                                />
                            </Row>
                            {currentUser ? (
                                <>
                                    <Row justify="center">
                                        <p>Welcome {currentUser.email}</p>
                                    </Row>
                                    <Row justify="center">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            icon={<LoginOutlined />}
                                            size="large"
                                            href="/dashboard"
                                        >
                                            Dashboard
                                        </Button>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row justify="center">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            icon={<UserAddOutlined />}
                                            size="large"
                                            href="/signup"
                                        >
                                            Create new account
                                        </Button>
                                    </Row>
                                    <Row justify="center">
                                        <Button
                                            shape="round"
                                            icon={<LoginOutlined />}
                                            size="large"
                                            href="/login"
                                        >
                                            Log In
                                        </Button>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                <Footer>© 2021 DropMoments, Inc</Footer>
            </Content>
        </Layout>
    )
}

export default Home
