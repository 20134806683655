import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/home/Home'
import Dashboard from './components/dashboard/Dashboard'
import LogIn from './components/login/LogIn'
import SignUp from './components/signup/SignUp'
import { AuthProvider } from './components/context/Auth'
import NotFound from './components/notfound/NotFound'

const App = () => (
    <AuthProvider>
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/login" component={LogIn} />
                <Route exact path="/signup" component={SignUp} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    </AuthProvider>
)

export default App
