import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

firebase.initializeApp({
    apiKey: 'AIzaSyDWYdCVnS-h1Y9tjCA-U_gMUDKpCrqUba8',
    authDomain: 'dropmoments-a40c9.firebaseapp.com',
    projectId: 'dropmoments-a40c9',
    storageBucket: 'dropmoments-a40c9.appspot.com',
    messagingSenderId: '682983242447',
    appId: '1:682983242447:web:79b2302bb6c47eb32d95ba'
})

const db = firebase.firestore()

const fire = {
    firebase,
    db
}

export default fire
