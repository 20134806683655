import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import firebase from '../../firebase'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState(null)
    useEffect(() => {
        firebase.firebase.auth().onAuthStateChanged((user) => {
            setCurrentUser(user)
            setLoading(false)
        })
    }, [])
    if (loading) {
        return (
            <div className="flex-center">
                <Spin size="large" />
            </div>
        )
    }
    return (
        <AuthContext.Provider value={{ currentUser }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.object
}
