import React, { useContext } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Form, Input, Checkbox, Button } from 'antd'
import fire from '../../firebase'
import { AuthContext } from '../context/Auth'
import Logo from '../logo/Logo'
import './SignUp.css'

const SignUp = () => {
    const { currentUser } = useContext(AuthContext)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        fire.firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password)
            .then(() => {
                fire.db
                    .collection('users')
                    .doc(fire.firebase.auth().currentUser.uid)
                    .set({
                        email: values.email,
                        nickname: values.nickname,
                        agreement: values.agreement
                    })
                    .catch((error) => {
                        console.log('Error adding user to firestore: ', error)
                    })
            })
            .catch((error) => {
                console.log('Error during signup: ', error)
            })
    }

    if (currentUser) return <Redirect to="/dashboard" />

    return (
        <div className="signup-container">
            <Link to="/">
                <Logo size={50} color="#1890ff" strokeWidth={2.0} fill="none" />
            </Link>
            <Form
                form={form}
                name="signup"
                className="signup-form"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Form.Item
                    name="nickname"
                    placeholder="Nickname"
                    tooltip="What do you want others to call you?"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your nickname!',
                            whitespace: true
                        }
                    ]}
                >
                    <Input placeholder="Nickname" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!'
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!'
                        }
                    ]}
                >
                    <Input placeholder="Email address" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!'
                        }
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve()
                                }

                                return Promise.reject(
                                    new Error(
                                        'The two passwords that you entered do not match!'
                                    )
                                )
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="Confirm password" />
                </Form.Item>
                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error('Should accept agreement')
                                      )
                        }
                    ]}
                >
                    <Checkbox>
                        I have read the <a href="#agreement">agreement</a>
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="signup-form-button"
                    >
                        Create your account
                    </Button>
                    already have an acccount? <a href="/login">login.</a>
                </Form.Item>
            </Form>
        </div>
    )
}

export default SignUp
