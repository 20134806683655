import React from 'react'
import { Redirect } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import {
    InboxOutlined,
    AppstoreAddOutlined,
    LogoutOutlined,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons'
import { AuthContext } from '../context/Auth'
import fire from '../../firebase'
import Logo from '../logo/Logo'
import './Dashboard.css'
const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu

class Dashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            collapsed: true,
            userData: {
                nickname: null,
                email: null,
                agreement: null
            }
        }
    }

    onCollapse = (collapsed) => {
        this.setState({ collapsed })
    }

    componentDidMount() {
        const user = fire.firebase.auth().currentUser
        if (user) {
            this.fetchUser(user)
        }
    }

    fetchUser = async (user) => {
        const userResponse = await fire.db.doc(`users/${user.uid}`).get()
        this.setState({
            userData: {
                nickname: userResponse.data().nickname,
                email: userResponse.data().email,
                agreement: userResponse.data().agreement
            }
        })
    }

    render() {
        const { collapsed } = this.state

        if (!this.context.currentUser) return <Redirect to="/login" />

        return (
            <Layout className="layout-dashboard" style={{ minHeight: '100vh' }}>
                <Sider
                    theme="dark"
                    collapsible
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                >
                    <Logo
                        size={35}
                        color="#f0f2f5"
                        strokeWidth={1.5}
                        fill="none"
                    />
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={['1']}
                        mode="inline"
                    >
                        <Menu.Item key="1" icon={<AppstoreAddOutlined />}>
                            Create
                        </Menu.Item>
                        <Menu.Item key="2" icon={<InboxOutlined />}>
                            Memories
                        </Menu.Item>
                        <SubMenu
                            key="sub1"
                            icon={<UserOutlined />}
                            title="User"
                        >
                            <Menu.Item key="3">Profile</Menu.Item>
                            <Menu.Item key="4">Permissions</Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="sub2"
                            icon={<SettingOutlined />}
                            title="Settings"
                        >
                            <Menu.Item key="6">Settings 1</Menu.Item>
                            <Menu.Item key="8">Settings 2</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="9" icon={<LogoutOutlined />}>
                            {/* eslint-disable-next-line */}
              <a onClick={() => fire.firebase.auth().signOut()}>Sign out</a>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{ padding: 0 }}
                    />
                    <Content style={{ margin: '0 16px' }}>
                        <div
                            className="site-layout-background"
                            style={{
                                margin: '15px 0px',
                                padding: 24,
                                minHeight: 360
                            }}
                        >
                            <p>Hello {this.state.userData.nickname}</p>
                            <p>Email: {this.state.userData.email}</p>
                            <p>
                                Agreement:{' '}
                                {String(this.state.userData.agreement)}
                            </p>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        © 2021 Drop Moments
                    </Footer>
                </Layout>
            </Layout>
        )
    }
}

Dashboard.contextType = AuthContext

export default Dashboard
